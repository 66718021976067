import * as React from "react";
// import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
// import MotionInView from "../animations/MotionInView";
// import { varFadeIn } from "../animations/variants/Fadein";

// ----------------------------------------------------------------------

// const RootStyle = styled("div")(() => ({
//   height: "95vh",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   backgroundColor: "Background.paper",
// }));

export default function Loading() {
  return (
    <div className='flex items-center h-screen justify-center'>
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    </div>
    // <RootStyle>
    //   <Stack alignItems="center" justifyContent="center">
    //     <MotionInView variants={varFadeIn}>
    //       <Stack alignItems="center" justifyContent="center">
    //         <Box
    //           component="img"
    //           src="/icons/loading.gif"
    //           sx={{ width: "450px", height: "50px", objectFit: "cover" }}
    //         />
    //       </Stack>
    //     </MotionInView>
    //   </Stack>
    // </RootStyle>
  );
}
