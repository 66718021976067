import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { readGames } from "./stores/game-actions";
import { auth } from "./firebase/firebase";
import { authActions } from "./stores/auth-slice";
import Loading from "./components/ui/Loading";
import { gamesActions } from "./stores/games-slice";
import NotFound from "./components/pages/NotFound";
import Form from "./components/admin/Form";
import ReactGa from "react-ga4";

const LazyHome = React.lazy(() => import("./components/pages/Home"));
const LazyAdmin = React.lazy(() => import("./components/pages/Admin"));
const LazyGameDetail = React.lazy(() => import("./components/Game/GameDetail"));
function App() {
  const dispatch = useDispatch();
  const games = useSelector((state) => state.games.gamesList);
  useEffect(() => {
    auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        const user = {
          uid: authUser?.uid,
          email: authUser?.email,
        };
        dispatch(authActions.setUser(user));
      } else {
        dispatch(authActions.setUser(null));
      }
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(readGames());
    // Initializing Google Analytic
    ReactGa.initialize("G-JY45LZ1K15");
  }, [dispatch]);

  useEffect(() => {
    dispatch(gamesActions.fetchGames(games));
  }, [games, dispatch]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <React.Suspense fallback={<Loading />}>
            <LazyHome />
          </React.Suspense>
        }
      />
      <Route
        path="/admin"
        element={
          <React.Suspense fallback={<Loading />}>
            <LazyAdmin />
          </React.Suspense>
        }
      />
      <Route />
      <Route path="/login" element={<Form />} />
      <Route
        path="/game/:id"
        element={
          games.length > 0 && (
            <React.Suspense fallback={<Loading />}>
              <LazyGameDetail />
            </React.Suspense>
          )
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
