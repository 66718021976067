import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import ReactGa from "react-ga4";

const NotFound = () => {
  useEffect(() => {
    ReactGa.send({hitType: 'pageview', page: window.location.pathname});
  }, []);
  return (
    <div
      className="text-center h-screen bg-no-repeat bg-cover"
      style={{ backgroundImage: "url('./images/bg.jpg')" }}
    >
      <div className="relative top-[40%] text-white">
        <p className=" text-ellipsis text-5xl mb-3">404</p>
        <FontAwesomeIcon
          icon={faCircleExclamation}
          className="text-6xl mb-3"
        ></FontAwesomeIcon>
        <p className="font-bold font-comfortaa text-7xl mb-3">Page not found</p>
        <Button variant="contained" color="primary">
          <Link to="/">Back home</Link>
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
