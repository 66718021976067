import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    alert: null,
    uploadDone: false,
  },
  reducers: {
    showAlert(state, action) {
      state.alert = {
        message: action.payload.message,
        type: action.payload.type,
        open: action.payload.open,
      };
    },
    setUploadDone(state, action) {
      state.uploadDone = action.payload;
    },
  },
});

export const uiActions = uiSlice.actions;
export default uiSlice;
