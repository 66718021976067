import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import ReactGa from "react-ga4";
import { useSelector } from "react-redux";
const Form = () => {
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const navigate = useNavigate();
  const [loginStatus, setLoginStaus] = useState("");
  const user = useSelector((state) => state.auth.user)
  if(user){
    navigate('/admin')
  }
  const handleSignin = async (e) => {
    // sending event to Google analytic
    ReactGa.event({
      category: "Loggin in",
      action: "Click log in",
      label: "Button",
    });
    e.preventDefault();
    await signInWithEmailAndPassword(
      auth,
      emailRef.current.value,
      passwordRef.current.value
    )
      .then(() => {
        navigate("/admin");
      })
      .catch((err) => {
        setLoginStaus(err.code);
      });
  };
  

  return (
    <div className="w-full max-w-screen absolute sm:left-[25%] sm:max-w-sm md:left-[30%] lg:left-[40%] top-[30vh]">
      <form
        onSubmit={handleSignin}
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="username"
          >
            Email
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="Email"
            type="email"
            placeholder="Email"
            ref={emailRef}
          />
        </div>
        <div className="mb-6">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="password"
          >
            Password
          </label>
          <input
            className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            placeholder="******************"
            ref={passwordRef}
          />
          {loginStatus && (
            <span className="text-red text-xs italic">{loginStatus}</span>
          )}
        </div>
        <div className="text-center">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Sign In
          </button>
        </div>
      </form>
      <p className="text-center text-gray-500 text-xs">
        &copy;2020 Acme Corp. All rights reserved.
      </p>
    </div>
  );
};

export default Form;
