import { db, storage } from "../firebase/firebase";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import { v4 } from "uuid";
import { gamesActions } from "./games-slice";

export const postGame = (
  gameTitle,
  gameDescription,
  gameLevel,
  gameThumbnail,
  url = null,
  gameFile = null
) => {
  return async (dispatch) => {

    let thumbNailPath = "";
    let gameFilePath = "";
    let gameFileName = "";
    let thumbNailName = "";
    let thumbnailList = [];
    const game = await getDoc(doc(db, "games", gameTitle));
    if (game.exists()) {
      dispatch(
        gamesActions.setGameExist({ exist: game.exists(), title: gameTitle })
      );
      return true;
    } else {
      if (gameThumbnail) {
        const thumbnailRef = ref(
          storage,
          `${gameTitle}/gameThumbnails/${gameThumbnail.name}` + v4()
        );
        await uploadBytes(thumbnailRef, gameThumbnail).then(
          async (snapShot) => {
            thumbNailName = snapShot.metadata.fullPath;
            await getDownloadURL(snapShot.ref).then((url) => {
              thumbNailPath = url;
            });
          }
        );
      }
      if (gameFile) {
        const gameFileRef = ref(
          storage,
          `${gameTitle}/gameFiles/${gameFile.name}` + v4()
        );
        await uploadBytes(gameFileRef, gameFile).then(async (snapShot) => {
          gameFileName = snapShot.metadata.fullPath;
          await getDownloadURL(snapShot.ref).then((url) => {
            gameFilePath = url;
          });
        });
      }
      thumbnailList.push(thumbNailName);
      if (gameDescription && gameTitle && gameFile) {
        await setDoc(doc(db, "games", gameTitle), {
          gameTitle: gameTitle,
          gameDescription: gameDescription,
          gameLevel: gameLevel,
          thumbNailPath: thumbNailPath,
          gameFilePath: gameFilePath,
          gameFileName: gameFileName,
          thumbNailName: thumbNailName,
          thumbnailList,
          likes: 0,
          views: 0,
          played: 0,
          url: null,
          timestamp: serverTimestamp(),
        }).catch((err) => {
          console.log(err);
        });
        dispatch(
          gamesActions.addGames({
            id: gameTitle,
            gameTitle: gameTitle,
            gameDescription: gameDescription,
            gameLevel: gameLevel,
            thumbNailPath: thumbNailPath,
            gameFilePath: gameFilePath,
            gameFileName: gameFileName,
            thumbNailName: thumbNailName,
            thumbnailList,
            likes: 0,
            views: 0,
            played: 0,
            url: null,
            timestamp: new Date().toLocaleString(),
          })
        );
      }
      if (gameDescription && gameTitle && url) {
        await setDoc(doc(db, "games", gameTitle), {
          gameTitle: gameTitle,
          gameDescription: gameDescription,
          gameLevel: gameLevel,
          thumbNailPath: thumbNailPath,
          gameFilePath: null,
          gameFileName: null,
          thumbNailName: thumbNailName,
          thumbnailList,
          likes: 0,
          views: 0,
          played: 0,
          url: url,
          timestamp: serverTimestamp(),
        });
        dispatch(
          gamesActions.addGames({
            id: gameTitle,
            gameTitle: gameTitle,
            gameDescription: gameDescription,
            gameLevel: gameLevel,
            thumbNailPath: thumbNailPath,
            gameFilePath: null,
            gameFileName: null,
            thumbNailName: thumbNailName,
            thumbnailList,
            likes: 0,
            views: 0,
            played: 0,
            url: url,
            timestamp: new Date().toLocaleString(),
          })
        );
      }

      dispatch(gamesActions.setGameExist({ exist: game.exists(), title: "" }));
      return false;
    }
  };
};

export const updateGame = (
  gameId,
  gameTitle,
  gameDescription,
  gameLevel,
  gameThumbnail,
  game
) => {
  return async (dispatch) => {
    const gamefound = await getDoc(doc(db, "games", gameTitle));
    const gameData = gamefound.data();
    let thumbNailPath = "";
    let thumbNailName = "";
    let thumbnailList = [];
    if (game.gameTitle === gameTitle) {
      // no need to delete the old document in firebase if the gameTitle (ID) is still the same
      if (gameThumbnail) {
        //if there is any update on file, we need to push it into an array, so we can later delete it in firebase storage
        const thumbnailRef = ref(
          storage,
          `${gameTitle}/gameThumbnails/${gameThumbnail.name}` + v4()
        );
        await uploadBytes(thumbnailRef, gameThumbnail).then(
          async (snapShot) => {
            thumbNailName = snapShot.metadata.fullPath;
            await getDownloadURL(snapShot.ref).then((url) => {
              thumbNailPath = url;
            });
          }
        );
        gameData.thumbnailList.forEach((item) => {
          thumbnailList.push(item);
        });
        thumbnailList.push(thumbNailName);

        await updateDoc(doc(db, "games", gameId), {
          gameTitle: gameTitle,
          gameDescription: gameDescription,
          gameLevel: gameLevel,
          thumbNailName: thumbNailName,
          thumbNailPath: thumbNailPath,
          thumbnailList,
        });
        dispatch(
          gamesActions.updateGame({
            gameId: gameTitle,
            gameTitle,
            gameDescription,
            gameLevel,
            thumbNailName,
            thumbNailPath,
            gameFileName: game.gameFileName,
            gameFilePath: game.gameFilePath,
            thumbnailList,
            likes: game.likes,
            views: game.views,
            played: game.played,
            timestamp: game.timestamp,
          })
        );
        return false;
      }
      // if tehre is no file being updated, just update the text fields (title, description)
      await updateDoc(doc(db, "games", gameId), {
        gameTitle: gameTitle,
        gameDescription: gameDescription,
        gameLevel: gameLevel,
      });
      dispatch(
        gamesActions.updateGame({
          gameId: gameTitle,
          gameTitle,
          gameDescription,
          gameLevel,
          gameFileName: game.gameFileName,
          gameFilePath: game.gameFilePath,
          thumbNailName: game.thumbNailName,
          thumbNailPath: game.thumbNailPath,
          thumbnailList: game.thumbnailList,
          likes: game.likes,
          views: game.views,
          played: game.played,
          timestamp: game.timestamp,
        })
      );
      return false;
    } else {
      // if the game is being updated but with different game title (id) check if the game title exists
      if (gamefound.exists()) {
        // if exist return state
        dispatch(gamesActions.setGameExist({ exist: true, title: gameTitle }));
        return true;
      } else {
        // if not exist will delete old docuemnt in firestore

        if (gameThumbnail) {
          const thumbnailRef = ref(
            storage,
            `${gameTitle}/gameThumbnails/${gameThumbnail.name}` + v4()
          );
          await uploadBytes(thumbnailRef, gameThumbnail).then(
            async (snapShot) => {
              thumbNailName = snapShot.metadata.fullPath;
              await getDownloadURL(snapShot.ref).then((url) => {
                thumbNailPath = url;
              });
            }
          );
          gameData.thumbnailList.forEach((item) => {
            thumbnailList.push(item);
          });
          await deleteDoc(doc(db, "games", game.id)).catch((err) =>
            console.log(err)
          );

          dispatch(gamesActions.removeGame(game.id));

          await setDoc(doc(db, "games", gameTitle), {
            gameTitle: gameTitle,
            gameDescription: gameDescription,
            gameLevel: gameLevel,
            thumbNailPath: thumbNailPath,
            thumbNailName: thumbNailName,
            thumbnailList: thumbnailList,
            gameFilePath: game.gameFilePath,
            gameFileName: game.gameFileName,
            likes: game.likes,
            views: game.views,
            played: game.played,
            timestamp: serverTimestamp(),
          });
          dispatch(
            gamesActions.addGames({
              id: gameTitle,
              gameTitle: gameTitle,
              gameDescription: gameDescription,
              gameLevel: gameLevel,
              thumbNailPath: game.thumbNailPath,
              gameFilePath: game.gameFilePath,
              gameFileName: game.gameFileName,
              thumbNailName: game.thumbNailName,
              thumbnailList: game.thumbnailList,
              likes: game.likes,
              views: game.views,
              played: game.played,
            })
          );
          return false;
        }
        await deleteDoc(doc(db, "games", game.id)).catch((err) =>
          console.log(err)
        );
        dispatch(gamesActions.removeGame(game.id));

        await setDoc(doc(db, "games", gameTitle), {
          gameTitle: gameTitle,
          gameDescription: gameDescription,
          gameLevel: gameLevel,
          thumbNailPath: game.thumbNailPath,
          gameFilePath: game.gameFilePath,
          gameFileName: game.gameFileName,
          thumbNailName: game.thumbNailName,
          thumbnailList: game.thumbnailList,
          likes: game.likes,
          views: game.views,
          played: game.played,
          timestamp: serverTimestamp(),
        });
        dispatch(
          gamesActions.addGames({
            id: gameTitle,
            gameTitle: gameTitle,
            gameDescription: gameDescription,
            gameLevel: gameLevel,
            thumbNailPath: game.thumbNailPath,
            gameFilePath: game.gameFilePath,
            gameFileName: game.gameFileName,
            thumbNailName: game.thumbNailName,
            thumbnailList: game.thumbnailList,
            likes: game.likes,
            views: game.views,
            played: game.played,
          })
        );
        return false;
      }
    }
  };
};

export const readGames = (option = "timestamp") => {
  return async (dispatch) => {
    let res = null;
    if (option === "timestamp") {
      res = await getDocs(query(collection(db, "games"), orderBy(option)));
    } else {
      res = await getDocs(
        query(collection(db, "games"), orderBy(option, "desc"))
      ).catch((err) => console.log(err));
    }
    let games = [];
    res.forEach((docs) => {
      let { timestamp, ...other } = docs.data();
      timestamp = timestamp.toDate();
      timestamp = JSON.stringify({ timestamp });
      timestamp = JSON.parse(timestamp);
      games.push({
        timestamp: new Date(timestamp.timestamp).toLocaleString(),
        ...other,
        id: docs.id,
      });
    });
    if (option === "timestamp") {
      games.reverse();
    }
    dispatch(gamesActions.fetchGames(games));
  };
};

export const deleteGame = (game) => {
  return async (dispatch) => {
    await deleteDoc(doc(db, "games", game.id)).catch((err) => console.log(err));
    game.thumbnailList.forEach(async (item) => {
      await deleteObject(ref(storage, `${item}`)).catch((err) =>
        console.log(err)
      );
    });
    if (game.gameFilePath && game.gameFileName)
      await deleteObject(ref(storage, `${game.gameFileName}`)).catch((err) =>
        console.log(err)
      );
    dispatch(gamesActions.removeGame(game.id));
    dispatch(gamesActions.setSearchResult());
  };
};

export const incrementView = (gameId) => {
  return async (dispatch) => {
    if (gameId) {
      const gamefound = await getDoc(doc(db, "games", gameId));
      if (gamefound.exists()) {
        return await updateDoc(doc(db, "games", gameId), {
          views: gamefound.data().views + 1,
        });
      }
    }
  };
};
export const incrementPlay = (gameId) => {
  return async (dispatch) => {
    if (gameId) {
      const gamefound = await getDoc(doc(db, "games", gameId));
      if (gamefound.exists()) {
        return await updateDoc(doc(db, "games", gameId), {
          played: gamefound.data().played + 1,
        });
      }
    }
  };
};

export const incrementLike = (gameId) => {
  return async (dispatch) => {
    if (gameId) {
      const gamefound = await getDoc(doc(db, "games", gameId));
      const game = gamefound.data();
      if (gamefound.exists()) {
        await updateDoc(doc(db, "games", gameId), {
          likes: gamefound.data().likes + 1,
        });

        let timestamp = gamefound.data().timestamp;
        timestamp = timestamp.toDate();
        timestamp = JSON.stringify({ timestamp });
        timestamp = JSON.parse(timestamp);
        timestamp = new Date(timestamp.timestamp).toLocaleString();
        dispatch(
          gamesActions.updateGame({
            gameId: game.gameTitle,
            gameTitle: game.gameTitle,
            gameDescription: game.gameDescription,
            gameLevel: game.gameLevel,
            thumbNailName: game.thumbNailName,
            thumbNailPath: game.thumbNailPath,
            gameFileName: game.gameFileName,
            gameFilePath: game.gameFilePath,
            thumbnailList: game.thumbnailList,
            likes: game.likes + 1,
            views: game.views,
            played: game.played,
            timestamp: timestamp,
          })
        );
      }
    }
  };
};

export const decrementLike = (gameId) => {
  return async (dispatch) => {
    if (gameId) {
      const gamefound = await getDoc(doc(db, "games", gameId));
      const game = gamefound.data();
      if (gamefound.exists()) {
        await updateDoc(doc(db, "games", gameId), {
          likes: gamefound.data().likes - 1,
        });
        let timestamp = gamefound.data().timestamp;
        timestamp = timestamp.toDate();
        timestamp = JSON.stringify({ timestamp });
        timestamp = JSON.parse(timestamp);
        timestamp = new Date(timestamp.timestamp).toLocaleString();
        dispatch(
          gamesActions.updateGame({
            gameId: game.gameTitle,
            gameTitle: game.gameTitle,
            gameDescription: game.gameDescription,
            gameLevel: game.gameLevel,
            thumbNailName: game.thumbNailName,
            thumbNailPath: game.thumbNailPath,
            gameFileName: game.gameFileName,
            gameFilePath: game.gameFilePath,
            thumbnailList: game.thumbnailList,
            likes: game.likes - 1,
            views: game.views,
            played: game.played,
            timestamp: timestamp,
          })
        );
      }
    }
  };
};
