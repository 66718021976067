import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    loginStatus: {
      loggedIn: false,
      error: null,
    },
  },
  reducers: {
    setUser(state, action) {
      if (action.payload) {
        state.user = {
          uid: action.payload.uid,
          email: action.payload.email,
        };
      }
    },
    setLoginStatus(state, action) {
      state.loginStatus = {
        loggedIn: action.payload.loggedIn,
        error: action.payload.error,
      };
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice;
