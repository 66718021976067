import { createSlice } from "@reduxjs/toolkit";

const gamesSlice = createSlice({
  name: "games",
  initialState: {
    gamesList: [],
    gameState: false,
    gameExist: {
      exist: false,
      title: "",
    },
    searchGameResult: [],
    displayGame: false,
    gameLike: null,
  },
  reducers: {
    fetchGames(state, action) {
      state.gameState = true;
      state.gamesList = action.payload;
    },
    addGames(state, action) {
      state.gamesList.push(action.payload);
    },
    updateGame(state, action) {
      state.gamesList.forEach((item, index) => {
        if (item.id === action.payload.gameId) {
          state.gamesList[index] = { ...action.payload, id: item.id };
        }
      });
    },
    setGameListState(state) {
      state.gameState = !state.gameState;
    },
    removeGame(state, action) {
      state.gamesList = state.gamesList.filter(
        (item) => item.id !== action.payload
      );
    },
    setGameExist(state, action) {
      state.gameExist = {
        exist: action.payload.exist,
        title: action.payload.title,
      };
    },
    searchGame(state, action) {
      let title = action.payload;
      state.searchGameResult = state.gamesList.filter((item) =>
        item.gameTitle.toLowerCase().includes(title)
      );
    },
    setSearchResult(state) {
      state.searchGameResult = [];
    },
    setDisplayGame(state, action) {
      state.displayGame = action.payload;
    },
    setGameLike(state, action) {
      state.gameLike = action.payload;
    },
  },
});

export const gamesActions = gamesSlice.actions;

export default gamesSlice;
